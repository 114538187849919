import React from 'react'

import Layout from '../components/layout'

import './projects.css'
import { graphql } from 'gatsby'
import { StyledIconTag } from '../components/tag'

const Actions = ({ published, live_url, githuburl }) => {
  return published ? (
    <ul className="actions">
      <a className="link" href={live_url}>
        View Live
      </a>
      <a className="link" href={githuburl}>
        View Source
      </a>
    </ul>
  ) : (
    <span className="actions coming-soon">Coming soon</span>
  )
}

const ProjectsPage = ({ data }) => {
  let projects = data.allProjectsJson.edges
    .map(obj => obj.node)
    .filter(project => project.published || true)
  return (
    <Layout>
      <ul className="projects">
        {projects.map((project, index) => {
          return (
            <li className="project" key={index}>
              <h3 className="project-name">{project.name}</h3>
              <p className="description">{project.description}</p>
              <ul className="technologies">
                {project.technologies.map((tech, index) => (
                  <StyledIconTag tag={tech} key={index} br="50%" />
                ))}
              </ul>
              <Actions
                published={project.published}
                live_url={project.live_url}
                githuburl={project.githuburl}
              />
            </li>
          )
        })}
      </ul>
    </Layout>
  )
}

export default ProjectsPage

export const query = graphql`
  {
    allProjectsJson {
      edges {
        node {
          name
          description
          technologies
          githuburl
          live_url
          published
        }
      }
    }
  }
`
